import React, { useEffect, useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import { FiExternalLink } from "@react-icons/all-files/fi/FiExternalLink";

import { SubscriptionPng } from "Assets";
import { Flex, Image, Text, Header, Box, Button } from "Atoms";
import { Dimmer, DimmerContent } from "Molecules";
import {
    findOnlineProductFixedSubscriptionDiscount,
    shouldDisplayStockStatusText,
    navigateToCompanySubscriptionPage,
    gaProductionEvent
} from "Utils";
import { usePos, useSubscriptions } from "Providers";
import { useLanguage } from "LanguageProvider";
import { getPriceAndDiscountText } from "../../../../admin/components/poses/pos/utils/priceUtils";
import { OnlineStrikeThroughPrice } from "./";
import {
    DISCOUNT_TYPE,
    GOOGLE_ANALYTICS_EVENT_ACTION,
    GOOGLE_ANALYTICS_EVENT_CATEGORY,
    OnlineFixedDiscount,
    OnlineProduct as OnlineProductType,
    RefBundleProduct,
    RefProduct,
    Subscription
} from "Types";
import { LanguageText } from "Components";
import { useOnlineStore, useOrderWindowsStore, usePosStore, useQoplaStore } from "Stores";
import {
    hasFullMenuFixedDiscount,
    hasProductOrCategoryFixedDiscount,
    transformFixedDiscountCategoryToProductIds
} from "OnlineUtils";
import { OnlinePromotionProduct } from "OnlineComponents";
import { ONLINE_SEARCH_CATEGORY_ID } from "Constants";

//amount of non caps characters, which takes 2 lines in description
const SHORT_DESCRIPTION = 20;

type ProductProps = {
    menuProductCategoryId: string;
    onlineProduct: OnlineProductType;
    isUpsell?: boolean;
    isInSubscriptionCategory?: boolean;
    isDisabled: boolean;
    quantityInCart?: number;
    productHasPromotion?: boolean;
};

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const OnlineProduct: React.FC<ProductProps> = React.memo(
    ({
        menuProductCategoryId,
        onlineProduct,
        isUpsell = false,
        isInSubscriptionCategory = false,
        isDisabled,
        quantityInCart,
        productHasPromotion = false
    }) => {
        const {
            setRestaurantProductModalState: toggleProductModal,
            partnerPromotion,
            activeMenu: onlineMenu
        } = useOnlineStore();
        const {
            getActiveOrderWindow,
            allActiveFixedDiscounts,
            activeFixedMenuDiscount,
            findMenuProductCategoryForMenuProduct
        } = useOrderWindowsStore();
        const { selectedActiveMenuId } = usePosStore();
        const orderWindow = getActiveOrderWindow();
        const { selectedShop } = useQoplaStore();

        const shopId = selectedShop?.id;
        const displayPromotion = productHasPromotion && !!partnerPromotion?.id && partnerPromotion?.menuProductImageUrl;

        /** If this is a product that is in the search view - need to check if the active discount
         * has full category discount as the category id passed is the search category id
         */
        const isSearchCategory = menuProductCategoryId === ONLINE_SEARCH_CATEGORY_ID;
        const shouldTransformFixedDiscount =
            isSearchCategory && !!activeFixedMenuDiscount && !!onlineMenu && !!selectedActiveMenuId;
        const searchCategoryFixedDiscount = shouldTransformFixedDiscount
            ? transformFixedDiscountCategoryToProductIds(activeFixedMenuDiscount, onlineMenu, selectedActiveMenuId)
            : undefined;

        const { refProducts, getRemainingStockQuantity } = usePos();

        const [productQuantity, setProductQuantity] = useState<number>(quantityInCart || 0);

        const { translate, userLanguage } = useLanguage();
        const { userSubscriptions, subscriptions } = useSubscriptions();
        let isSubscriptionCard = false;

        const product = onlineProduct.refProduct
            ? (onlineProduct.refProduct as RefProduct)
            : (onlineProduct.refBundleProduct as RefBundleProduct);

        if (subscriptions?.length > 0) {
            isSubscriptionCard = subscriptions.some((value: Subscription) => value.id === product.id);
        }

        useEffect(() => {
            setProductQuantity(quantityInCart || 0);
        }, [quantityInCart]);

        // TODO clean this up
        const figureOutWhichDiscountToDisplay = () => {
            if (onlineProduct.subscriptionProductMeta) {
                const discount = findOnlineProductFixedSubscriptionDiscount(allActiveFixedDiscounts, onlineProduct);

                if (discount) {
                    const { isFree, price, discountPrice } = getPriceAndDiscountText(
                        onlineProduct,
                        refProducts,
                        discount,
                        userLanguage
                    );

                    return {
                        price,
                        discountPrice: isFree ? translate("free") : discountPrice,
                        hasFixedDiscount: true
                    };
                }
            } else if (activeFixedMenuDiscount && !isInSubscriptionCategory) {
                const isFullMenuDiscount = hasFullMenuFixedDiscount(
                    selectedActiveMenuId ?? "",
                    activeFixedMenuDiscount
                );

                const productOrCategoryHasFixedDiscount = hasProductOrCategoryFixedDiscount(
                    selectedActiveMenuId ?? "",
                    menuProductCategoryId,
                    onlineProduct.id,
                    /** Only need this here (fixed discount + search category) */
                    !!searchCategoryFixedDiscount ? searchCategoryFixedDiscount : activeFixedMenuDiscount
                );

                const _hasFixedDiscount = isFullMenuDiscount || productOrCategoryHasFixedDiscount;

                const { isFree, price, discountPrice } = getPriceAndDiscountText(
                    onlineProduct,
                    refProducts,
                    activeFixedMenuDiscount,
                    userLanguage
                );

                return {
                    price,
                    discountPrice: isFree ? translate("free") : discountPrice,
                    hasFixedDiscount: _hasFixedDiscount
                };
            }

            const { price } = getPriceAndDiscountText(onlineProduct, refProducts, null, userLanguage);

            return {
                price,
                discountPrice: 0,
                hasFixedDiscount: false
            };
        };

        const { price, discountPrice, hasFixedDiscount } = figureOutWhichDiscountToDisplay();

        const priceText = isUpsell && price === "0 kr" ? "" : price;
        const discountText = isUpsell && discountPrice === "0 kr" ? "" : discountPrice;

        const setSubscriptionDiscount = (): OnlineFixedDiscount => {
            return {
                menuId: selectedActiveMenuId,
                fixedDiscountId: onlineProduct.subscriptionProductMeta!.subscriptionId,
                menuCategoryId: menuProductCategoryId,
                menuProductId: onlineProduct.id,
                isFixedDiscountActive: true,
                canCombineDiscounts: false,
                type: DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT,
                subscriptionProductMeta: onlineProduct.subscriptionProductMeta
            } as OnlineFixedDiscount;
        };

        const setRegularFixedDiscount = (): OnlineFixedDiscount => {
            return {
                menuId: selectedActiveMenuId,
                fixedDiscountId: hasFixedDiscount ? activeFixedMenuDiscount?.id : "",
                menuCategoryId: menuProductCategoryId,
                menuProductId: onlineProduct.id,
                isFixedDiscountActive: hasFixedDiscount,
                canCombineDiscounts: hasFixedDiscount ? activeFixedMenuDiscount?.combineWithOtherDiscounts : true,
                type: DISCOUNT_TYPE.FIXED_DISCOUNT
            } as OnlineFixedDiscount;
        };

        const setFixedDiscount = () => {
            if (onlineProduct.subscriptionProductMeta) {
                return setSubscriptionDiscount();
            } else {
                return setRegularFixedDiscount();
            }
        };

        const openRestaurantProductModal = () => {
            if (onlineProduct.isOutOfStock) return;
            isUpsell &&
                gaProductionEvent({
                    category: GOOGLE_ANALYTICS_EVENT_CATEGORY.ONLINE_ORDER,
                    action: GOOGLE_ANALYTICS_EVENT_ACTION.ADD_UPSELL
                });

            const fixedDiscount = setFixedDiscount();

            const foundMenuProductCategory = findMenuProductCategoryForMenuProduct(onlineProduct.id);
            const _menuProductCategoryId = foundMenuProductCategory?.id ?? menuProductCategoryId;

            if (!_menuProductCategoryId) {
                console.warn(`Found no menuProductCategoryId for menuProduct with ID=${onlineProduct.id}`);
            }

            toggleProductModal({
                onlineProduct: onlineProduct,
                menuProductCategoryId: _menuProductCategoryId,
                shopId,
                isUpsell,
                fixedDiscount,
                isProductCommentsEnabled: selectedShop?.settings?.onlineSettings?.productCommentsEnabled || false
            });
        };

        const onProductClick = () => {
            const shouldNavigateToCompanySubscriptions =
                isInSubscriptionCategory && !userSubscriptions.length && !!subscriptions.length;

            if (shouldNavigateToCompanySubscriptions) {
                navigateToCompanySubscriptionPage();
            } else {
                openRestaurantProductModal();
            }
        };

        let imageUrl = !isSubscriptionCard ? product.imageUrl : "";
        if (isSubscriptionCard) {
            imageUrl = product.imageUrl ? product.imageUrl : SubscriptionPng;
        }

        const pseudoStyles = {
            transform: isDisabled ? "" : ["", "", "", "scale(1.01)"],
            boxShadow: isDisabled
                ? ""
                : ["", "", "", "0 8px 12px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"]
        };

        const onlineProductStock = getRemainingStockQuantity(
            onlineProduct?.refProduct?.id || onlineProduct?.refBundleProduct?.id
        );

        const isOutOfStock = onlineProduct.isOutOfStock || onlineProductStock === 0;
        const showPromotion = displayPromotion && !isOutOfStock && productQuantity === 0;
        //if product has a short description, let product name take 2 lines
        const reservedLinesNumberForName = product.description?.length <= SHORT_DESCRIPTION ? 2 : 1;

        return (
            <Box height="auto" width="100%" minWidth="100%">
                <Dimmer isActive={isOutOfStock} w="full">
                    <Button
                        onClick={onProductClick}
                        isDisabled={isDisabled}
                        position="relative"
                        border="none"
                        padding="0"
                        height="100px"
                        maxHeight="100px"
                        fontWeight={400}
                        borderRadius="0.75rem"
                        width="100%"
                        whiteSpace="pre-wrap"
                        backgroundColor="base"
                        cursor={isDisabled ? "not-allowed" : "pointer"}
                        overflow="hidden"
                        textAlign="left"
                        boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
                        transition="all 0.2s cubic-bezier(0.44, 2.1, 0.04, 1.65)"
                        _hover={pseudoStyles}
                        _active={pseudoStyles}
                    >
                        {imageUrl && (
                            <Flex flex="0 0 100px" padding={1}>
                                <Image
                                    alt={product.name}
                                    src={imageUrl}
                                    fallbackSrc={""}
                                    maxHeight="100px"
                                    maxWidth="100px"
                                    objectFit="cover"
                                    borderRadius="0.75rem"
                                    opacity={isDisabled ? 0.5 : 1}
                                />
                            </Flex>
                        )}
                        <Flex flex="1">
                            <Flex direction="column" p={3} flex="1">
                                <Header size="md" as="h4" margin="0" wordBreak="break-word">
                                    {/** @ts-ignore */}
                                    <ResponsiveEllipsis
                                        text={product.name}
                                        maxLine={reservedLinesNumberForName}
                                        ellipsis="..."
                                        trimRight
                                        basedOn="letters"
                                    />
                                </Header>
                                <Flex direction="row" flex="1" justifyContent="space-between">
                                    <Box width="auto">
                                        <Box wordBreak="break-word">
                                            {!!product?.description && (
                                                <>
                                                    {/*@ts-ignore*/}
                                                    <ResponsiveEllipsis
                                                        text={product.description}
                                                        maxLine={hasFixedDiscount ? 2 : 3}
                                                        ellipsis="..."
                                                        trimRight
                                                        basedOn="letters"
                                                    />
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                    <Flex
                                        direction="column"
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                        flex="none"
                                    >
                                        {priceText &&
                                            (hasFixedDiscount ? (
                                                <>
                                                    <OnlineStrikeThroughPrice colour="red.500" marginLeft="1.6rem">
                                                        <Text fontWeight="bold" fontSize="0.9rem">
                                                            {priceText}
                                                            {isSubscriptionCard ? `/${translate("mon")}` : ""}
                                                        </Text>
                                                    </OnlineStrikeThroughPrice>
                                                    <Text
                                                        fontWeight="bold"
                                                        fontSize="1rem"
                                                        color="red.500"
                                                        pt={0}
                                                        mt={0}
                                                    >
                                                        {discountText}
                                                    </Text>
                                                </>
                                            ) : (
                                                <Text fontWeight="bold" fontSize="1.2rem" whiteSpace="nowrap">
                                                    {priceText}
                                                    {isSubscriptionCard ? `/${translate("mon")}` : ""}
                                                </Text>
                                            ))}
                                    </Flex>
                                </Flex>
                            </Flex>
                            {isInSubscriptionCategory && !onlineProduct.subscriptionProductMeta && (
                                <Box
                                    position="absolute"
                                    right="5px"
                                    top="5px"
                                    color="blue.500"
                                    size="20px"
                                    as={FiExternalLink}
                                />
                            )}
                            {shouldDisplayStockStatusText(onlineProductStock) && (
                                <LanguageText
                                    tid="fewLeft"
                                    fontSize="sm"
                                    textTransform="uppercase"
                                    letterSpacing="1px"
                                    fontWeight="bold"
                                    position="absolute"
                                    color="teal.500"
                                    right={productQuantity > 0 || displayPromotion ? "45px" : "5px"}
                                    top="5px"
                                />
                            )}
                            <Box position="absolute" right="0px" top="0px">
                                {productQuantity > 0 && (
                                    <Text
                                        backgroundColor="newPrimary"
                                        color="newPrimaryFont"
                                        padding={"0.5rem 1rem"}
                                        textAlign="center"
                                        alignSelf="flex-end"
                                        borderRadius="0 0.75rem"
                                    >
                                        {productQuantity}
                                    </Text>
                                )}
                                {showPromotion && <OnlinePromotionProduct />}
                            </Box>
                        </Flex>
                    </Button>
                    <DimmerContent rounded="lg" bg="blackAlpha.800">
                        <Header size="md" as="h3" margin="0" color="#FFF" wordBreak="break-word">
                            {product.name} {translate("outOfStock")}
                        </Header>
                    </DimmerContent>
                </Dimmer>
            </Box>
        );
    },
    (prevProps, nextProps) => {
        const hasSameQuantityInCart = prevProps.quantityInCart === nextProps.quantityInCart;
        const hasSameDisabledStatus = prevProps.isDisabled === nextProps.isDisabled;
        const hasSameSubscriptionProductMeta =
            prevProps.onlineProduct.subscriptionProductMeta === nextProps.onlineProduct.subscriptionProductMeta;

        return hasSameQuantityInCart && hasSameDisabledStatus && hasSameSubscriptionProductMeta;
    }
);
