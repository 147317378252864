import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

import { Flex, Image, Text } from "Atoms";
import { useOnline } from "Providers";
import { OnlineProductCategory, PartnerPromotionImageType } from "Types";
import { SubscriptionCountDown } from "./components";
import { OnlineHeader } from "OnlineComponents";
import { OnlinePromotionCategory } from "OnlineComponents";
import { useOnlineStore, useQoplaStore } from "Stores";

type Props = {
    onlineProductCategory: OnlineProductCategory;
};

export const OnlineMenuProductCategoryBanner: React.FC<Props> = ({ onlineProductCategory }) => {
    const { selectedShop } = useQoplaStore();
    const { isOnlineExpress } = useOnline();
    const { partnerPromotion } = useOnlineStore();
    const { imageUrl, name, description, isSubscriptionCategory, partnerPromotionImageType } = onlineProductCategory;
    const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

    let styles = {};

    if (!!isSubscriptionCategory) {
        styles = {
            direction: "column"
        };
    } else {
        styles = {
            align: "center"
        };
    }
    const isPromotedCategory = partnerPromotionImageType === PartnerPromotionImageType.CATEGORY_AND_PRODUCTS;
    const isPartnerProductsCategory = partnerPromotionImageType === PartnerPromotionImageType.CATEGORY;
    const showPromotionImage = selectedShop?.settings.partnerPromotionId && !isOnlineExpress;
    return (
        <Flex h="auto" p={2} mb={3} align="center">
            {showPromotionImage && isPartnerProductsCategory ? (
                <Image
                    alt={name}
                    src={partnerPromotion?.promotedCategoryImageUrl}
                    h="60px"
                    w="60px"
                    objectFit="cover"
                    rounded="full"
                    mr={3}
                />
            ) : (
                imageUrl && (
                    <Image alt={name} src={imageUrl} h="50px" w="50px" objectFit="cover" rounded="full" mr={3} />
                )
            )}
            <Flex flex="1" {...styles}>
                <Flex justify="space-between" h="auto" mb={1} align="center">
                    <OnlineHeader
                        as="h2"
                        size="lg"
                        fontWeight="normal"
                        fontSize={isOnlineExpress ? "30px" : "24px"}
                        m={0}
                    >
                        {name}
                    </OnlineHeader>
                    {isSubscriptionCategory && <SubscriptionCountDown id={onlineProductCategory.id} />}
                    {showPromotionImage && isPromotedCategory && (
                        <OnlinePromotionCategory
                            categoryType={partnerPromotionImageType}
                            position={"static"}
                            mt={0}
                            fontSize={"24px"}
                        />
                    )}
                </Flex>
                {description && (
                    <>
                        {!isSubscriptionCategory && (
                            <Text as="span" mx={2}>
                                |
                            </Text>
                        )}
                        {/** @ts-ignore */}
                        <ResponsiveEllipsis text={description} maxLine={2} ellipsis="..." trimRight basedOn="letters" />
                    </>
                )}
            </Flex>
        </Flex>
    );
};
