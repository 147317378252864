import React from "react";

import { Box } from "Atoms";
import { useOnline, useSubscriptions } from "Providers";
import {
    CartProduct,
    CustomerMetaStatus,
    DISCOUNT_TYPE,
    OnlineProductCategory as OnlineProductCategoryType,
    PartnerPromotionImageType,
    RefBundleProduct,
    RefProduct,
    SelectedBundleProductItem
} from "Types";
import { dimExpress, dimRegular } from "../../../utils";
import { OnlineMenuProductCategoryBanner } from "./OnlineMenuProductCategoryBanner/OnlineMenuProductCategoryBanner";
import { OnlineProduct } from "OnlineComponents";
import { useOrderWindowSocketStore, useOrderWindowsStore } from "Stores";
import "../../../../../pages/onlineOrder/onlineOrder.scss";

type Props = {
    onlineProductCategory: OnlineProductCategoryType;
    isMobile: boolean;
    noTodayActiveHours: boolean;
    isInactiveMenu: boolean;
    quantityInCategory: number;
    /** Only passed down if search is used */
    promotedProductIds?: string[];
};

export const OnlineProductCategory: React.FC<Props> = React.memo(
    ({
        onlineProductCategory,
        isMobile,
        noTodayActiveHours,
        isInactiveMenu,
        quantityInCategory,
        promotedProductIds,
        ...rest
    }) => {
        // For some reason, importing only getActiveOrderWindow from useOrderWindowsStore does not notify the component to re-render
        // However, importing orderWindows does. So we import both and use getActiveOrderWindow to get the active order window
        const { orderWindows, getActiveOrderWindow } = useOrderWindowsStore();
        const { browseMode, isOnlineExpress } = useOnline();
        const { subscriptionsOnHold } = useSubscriptions();
        const orderWindow = getActiveOrderWindow();
        const { nickname } = useOrderWindowSocketStore();

        const cartProducts = orderWindow?.cartProducts;

        /**
         * If this is a subscription category, we check the cart for already added subscription products
         * This is used to disable the products in the category
         */
        const hasAddedSubscriptionProduct =
            (onlineProductCategory?.isSubscriptionCategory &&
                orderWindow?.cartProducts?.some((cartProduct: CartProduct) => {
                    const isSubscriptionDiscount =
                        cartProduct.fixedDiscount?.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT;
                    const isFreeSubscriptionProduct =
                        cartProduct.fixedDiscount?.subscriptionProductMeta?.percentageDiscount === 100;

                    return isSubscriptionDiscount && isFreeSubscriptionProduct;
                })) ||
            false;

        const isInBrowseMode = isOnlineExpress ? dimExpress(browseMode) : dimRegular(browseMode);

        const productHasPromotion =
            onlineProductCategory.partnerPromotionImageType === PartnerPromotionImageType.CATEGORY_AND_PRODUCTS;

        return (
            <Box id={onlineProductCategory.id} ref={onlineProductCategory.ref} {...rest}>
                {!isMobile && <OnlineMenuProductCategoryBanner onlineProductCategory={onlineProductCategory} />}

                <Box className="online-order-card-grid">
                    {onlineProductCategory.onlineProducts.map((onlineProduct, index) => {
                        let disabledConditions = [
                            isInBrowseMode,
                            onlineProduct.isOutOfStock,
                            noTodayActiveHours,
                            isInactiveMenu
                        ];

                        if (onlineProduct.subscriptionProductMeta?.percentageDiscount === 100) {
                            const isSubscriptionCategoryOnHold = !!subscriptionsOnHold[onlineProductCategory.id];

                            disabledConditions = [
                                ...disabledConditions,
                                hasAddedSubscriptionProduct,
                                isSubscriptionCategoryOnHold
                            ];
                        }
                        const hasSearchPromotedProduct = promotedProductIds?.includes(onlineProduct.id) ?? false;
                        /**
                         * NOTE: isDisabled and productIsInCart are SUPER important for optimization here
                         * They are the properties that dictate whether the OnlineProduct is memoized.
                         * If you mess with these you risk re-rendering all of the products on ANY cart change, which is
                         * what we were doing from ~2019->2022, so maybe not so bad :)
                         */
                        const isDisabled = disabledConditions.some(value => value);

                        const product = onlineProduct.refProduct
                            ? (onlineProduct.refProduct as RefProduct)
                            : (onlineProduct.refBundleProduct as RefBundleProduct);

                        const cartProductsContainingThisProduct = (cartProducts || []).filter(
                            (cartProduct: CartProduct) => {
                                // Is this product the same as the cartProduct ?
                                const cartMenuProduct =
                                    cartProduct.menuProduct?.refProduct ||
                                    cartProduct.menuBundleProduct?.refBundleProduct;
                                if (product?.id === cartMenuProduct?.id) {
                                    return true;
                                }

                                // Is product included in this bundle?
                                if (cartProduct.menuBundleProduct?.refBundleProduct) {
                                    const hasSelectedBundleItem =
                                        !!cartProduct.orderProduct?.selectedBundleProductItems?.find(
                                            (selectedBundleItem: SelectedBundleProductItem) =>
                                                selectedBundleItem.refProductId == product?.id
                                        );
                                    return hasSelectedBundleItem;
                                }
                                return false;
                            }
                        );

                        // Sum all order product quantities related to this product
                        const productQuantityInCart = cartProductsContainingThisProduct
                            ?.filter(
                                (cartProduct: CartProduct) =>
                                    cartProduct?.customerMeta?.status == CustomerMetaStatus.ADDED
                            )
                            ?.map((cartProduct: CartProduct) => cartProduct.orderProduct.quantity)
                            .reduce((prev, curr) => prev + curr, 0);

                        const onlineProductKey = `${onlineProduct.id}-${index}`;

                        return (
                            <OnlineProduct
                                key={onlineProductKey}
                                menuProductCategoryId={onlineProductCategory.id}
                                isInSubscriptionCategory={onlineProductCategory.isSubscriptionCategory}
                                onlineProduct={onlineProduct}
                                isDisabled={isDisabled}
                                quantityInCart={productQuantityInCart}
                                productHasPromotion={productHasPromotion || hasSearchPromotedProduct}
                            />
                        );
                    })}
                </Box>
            </Box>
        );
    },
    (prevProps, nextProps) =>
        prevProps.quantityInCategory == nextProps.quantityInCategory &&
        prevProps?.onlineProductCategory?.ref == nextProps?.onlineProductCategory?.ref &&
        prevProps.onlineProductCategory.onlineProducts.length ==
            nextProps.onlineProductCategory.onlineProducts.length &&
        (prevProps?.onlineProductCategory?.isSubscriptionCategory || false) != true
);
